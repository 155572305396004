import isInViewport from "@utils/isInViewport";
import { useEffect, useState } from "react";
import Button from "./component/Button";
import { ReactComponent as HomeSVG } from "@images/icons/home.svg";
import { ReactComponent as AboutSVG } from "@images/icons/about.svg";
import { ReactComponent as ProjectsSVG } from "@images/icons/projects.svg";
import { ReactComponent as SkillsSVG } from "@images/icons/skills.svg";
import { ReactComponent as ContactSVG } from "@images/icons/contact.svg";

const ScrollButtons = () => {
  const [projectVisible, setProjectVisible] = useState(false);
  const [homeVisible, setHomeVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [skillsVisible, setSkillsVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);

  useEffect(() => {
    setActiveButton();
  }, []);

  const setActiveButton = () => {
    setProjectVisible(isInViewport("projects"));
    setHomeVisible(isInViewport("home"));
    setAboutVisible(isInViewport("about"));
    setContactVisible(isInViewport("contact"));
    setSkillsVisible(isInViewport("skills"));
    setHomeVisible(window.scrollY === 0);
  };

  window.addEventListener("scroll", setActiveButton);

  return (
    <div className="fixed md:top-0 md:bg-transparent bottom-5 md:right-5 right-0 md:w-1/12 w-full flex justify-center items-end md:h-full h-fit md:flex-col flex-row md:gap-1 gap-2 md:m-0">
      <Button
        Icon={HomeSVG}
        setIsActive={setHomeVisible}
        isActive={homeVisible}
        elementId="home"
      />
      <Button
        Icon={AboutSVG}
        setIsActive={setAboutVisible}
        isActive={aboutVisible}
        elementId="about"
      />
      <Button
        Icon={ProjectsSVG}
        setIsActive={setProjectVisible}
        isActive={projectVisible}
        elementId="projects"
      />
      <Button
        Icon={SkillsSVG}
        setIsActive={setSkillsVisible}
        isActive={skillsVisible}
        elementId="skills"
      />
      <Button
        Icon={ContactSVG}
        setIsActive={setContactVisible}
        isActive={contactVisible}
        elementId="contact"
      />
    </div>
  );
};

export default ScrollButtons;
